// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://siteapi.fre.com.br/',
  receiverEmail: 'suporte@fre.com.br',
  // apiURL: 'http://0.0.0.0:3000/',
  siteId: 1,
  capaSite: 'https://i.imgur.com/5AELk51.png',
  // capaSite: `https://images.unsplash.com/photo-1563520239648-a24e51d4b570?
  //               ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1
  //               &auto=format&fit=crop&w=1050&q=80`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
